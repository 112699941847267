import React, { useEffect, useState } from 'react';
import '../../css/modals/Counseling.css';

import { IoClose } from 'react-icons/io5';

import { useSelector, useDispatch } from 'react-redux';

import { closeCounseling } from '../../features/counseling';
import TransparentButton from '../buttons/TransparentButton';

const Advisory = () => {
	const [visibility, setVisibility] = useState('hidden');
	const [container, setContainer] = useState('advisory-closed');

	const modal = useSelector((state) => state.counseling.value.modal);

	const dispatch = useDispatch();

	useEffect(() => {
		if (modal) {
			setVisibility('visible');
			setTimeout(() => {
				setContainer('');
			}, 100);
		}
	}, [modal]);

	const handleCloseModal = (event) => {
		if (event.target.className === 'Advisory') {
			setContainer('advisory-closed');
			setTimeout(() => {
				// handleOpenModal(false);
				dispatch(closeCounseling());
				setVisibility('hidden');
			}, 200);
		}
	};

	return (
		<div className='Advisory' style={{ visibility: `${visibility}` }} onClick={handleCloseModal}>
			<div className={`advisory-container ${container}`}>
				<button
					className='advisory-closer'
					onClick={() => {
						setContainer('advisory-closed');
						setTimeout(() => {
							// handleOpenModal(false);
							dispatch(closeCounseling());
							setVisibility('hidden');
						}, 200);
					}}
				>
					<IoClose />
				</button>
				<h1>¿Necesitas una asesoría?</h1>
				<h3>Resolvemos tus dudas en tiempo real</h3>
				<p>Programa aquí tu charla gratuita en vivo.</p>
				<p>
					Si los horarios no te funcionan{' '}
					<a href='https://wa.link/q6b0dl' target='blank_'>
						escríbenos.
					</a>{' '}
				</p>

				<TransparentButton
					url='https://calendly.com/d/cqc4-92f-xhg/asesoria-personalizada-gratuita'
					name={'SOLICITAR ASESORÍA'}
					color={'#6000DE'}
					width={230}
				/>
			</div>
		</div>
	);
};

export default Advisory;
